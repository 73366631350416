<template>
  <main>
    <package-header
      title="Title Searches"
      subTitle="12764 Smith"
    ></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button
            class="btn btn-secondary d-lg-none mb-2"
            data-toggle="collapse"
            data-target="#package-nav"
            aria-expanded="false"
            aria-controls="package-nav"
          >
            <svg class="icon icon-menu">
              <use xlink:href="/icons/symbol-defs.svg#icon-menu"></use>
            </svg>
            <svg class="icon icon-close">
              <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
            </svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link
                to="/pages/title-searches/purchase"
                class="nav-link active"
              >
                Purchase
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/title-searches/manage" class="nav-link">
                Manage Purchases
              </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">
            <!-- Data entry form -->
            <div class="col-lg-12">
              <p class="lead">
                Select items to perform a title search. These will be conducted
                and processed for payment immediately.
              </p>

              <h3 class="mt-4">Select the PID(s) for your order</h3>
              <div class="custom-control custom-checkbox mb-3">
                <input
                  id="includeCancelledCharge"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="includeCancelledCharge"
                  >Include cancelled charges
                </label>
              </div>

              <table class="table table-stacked table-select-row mb-1">
                <thead class="bg-light">
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          id="th"
                          type="checkbox"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="th"
                          >&nbsp;
                        </label>
                      </div>
                    </th>
                    <th scope="col">PID</th>
                    <th scope="col">Short Legal Description</th>
                    <th style="width: 12rem" scope="col">Title</th>
                    <th scope="col">Registered Owner</th>
                    <th scope="col" class="text-right">Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems" :key="index">
                    <td scope="row">
                      <div
                        class="custom-control custom-checkbox"
                        v-if="!item.multiple"
                      >
                        <input
                          :id="item.a"
                          type="checkbox"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" :for="item.a"
                          >&nbsp;
                        </label>
                      </div>
                    </td>
                    <td data-header="PID">
                      <span class="td-content text-nowrap">{{ item.b }}</span>
                    </td>
                    <td data-header="Short Legal Description">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                    <td
                      data-header="Title"
                      :class="item.multiple && 'p-0'"
                      :colspan="item.multiple ? 3 : 1"
                    >
                      <span
                        v-if="!item.multiple"
                        class="td-content text-nowrap"
                        >{{ item.d }}</span
                      >

                      <table
                        class="table mb-0"
                        v-if="item.multiple && multipleItemsSelected"
                      >
                        <tr>
                          <td class="border-top-0" style="width: 12rem">
                            <svg class="icon text-info mr-1">
                              <use
                                xlink:href="/icons/symbol-defs.svg#icon-check"
                              ></use>
                            </svg>
                            BR211596 VA
                          </td>
                          <td class="border-top-0">SMART, BECKY</td>
                          <td class="border-top-0 text-right">$12.82</td>
                        </tr>
                        <tr>
                          <td>
                            <svg class="icon text-info mr-1">
                              <use
                                xlink:href="/icons/symbol-defs.svg#icon-check"
                              ></use>
                            </svg>
                            BM216728 VA
                          </td>
                          <td>DOE, JANE</td>
                          <td class="text-right">$12.82</td>
                        </tr>
                      </table>
                      <div class="p-1" v-if="item.multiple">
                        <strong
                          >Multiple titles found.
                          <a
                            data-toggle="modal"
                            data-target="#duplicateFiling"
                            href="#"
                            >Make selection</a
                          ></strong
                        >
                      </div>
                    </td>
                    <td data-header="Registered Owner" v-if="!item.multiple">
                      <span class="td-content">{{ item.e }}</span>
                    </td>
                    <td data-header="Fee" v-if="!item.multiple">
                      <span class="td-content text-right">{{ item.f }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6" class="text-right lead">
                      <strong><span class="pr-5">Total:</span> $25.64</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="text-right">
                <button class="btn btn-primary">Place Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Diplicate filing number modal -->
      <div
        class="modal fade"
        id="duplicateFiling"
        tabindex="-1"
        role="dialog"
        aria-labelledby="duplicateFilingLabel"
        aria-hidden="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="duplicateFilingLabel">
                Add New Charge, Lien, or Interest
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <svg class="icon icon-close">
                    <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
                  </svg>
                </span>
              </button>
            </div>
            <div class="modal-body">
              <p>
                Titles available for 015-671-572
                <strong>($12.82 per title)</strong>:
              </p>

              <table class="table table-stacked table-select-row">
                <thead class="bg-light">
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="all"
                        />
                        <label class="custom-control-label" for="all"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th scope="col">Title</th>
                    <th scope="col">Registered Owner</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in modalTableItems" :key="index">
                    <td style="width: 1rem" scope="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          :id="item.a"
                          type="checkbox"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" :for="item.a"
                          >&nbsp;
                        </label>
                      </div>
                    </td>
                    <td scope="col" data-header="Title">
                      <span class="td-content">{{ item.b }}</span>
                    </td>
                    <td scope="col" data-header="Registered Owner">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click="multipleItemsSelected = false"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="multipleItemsSelected = true"
                data-dismiss="modal"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward">
          <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use>
        </svg>
        Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>
import PackageHeader from '../../../components/PackageHeader.vue';
import PackageNavigation from '../../../components/PackageNavigation.vue';
import HelpPanel from '../../../components/HelpPanel.vue';

export default {
  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
  },

  data() {
    return {
      multipleItemsSelected: false,
      postSearch: false,
      tableItems: [
        {
          a: '1',
          b: '015-671-569',
          c: 'S/1592///A//8',
          d: 'KT119399 NE',
          e: 'SMART, BECKY',
          f: '$12.82',
          multiple: false,
        },
        {
          a: '2',
          b: '015-671-570',
          c: 'S/1592///A//9',
          d: 'KT119372 NE',
          e: 'ARMSTRONG, MARY JOANNE',
          f: '$12.82',
          multiple: false,
        },
        {
          a: '3',
          b: '015-671-571',
          c: 'S/1592///A//10',
          d: 'KT119948 NE',
          e: 'DOE, JANE',
          multiple: true,
        },
        {
          a: '4',
          b: '015-671-572',
          c: 'S/1592///A//11',
          d: 'KT119321 NE',
          e: 'PARK,SARAH',
          f: '$12.82',
          multiple: false,
        },
      ],

      modalTableItems: [
        {
          a: 'multi-1',
          b: 'BR211596 VA',
          c: 'SHAWNA, MIN',
        },
        {
          a: 'multi-2',
          b: 'BM216728 VA',
          c: 'ARMSTRONG, MARY JOANNE',
        },
        {
          a: 'multi-3',
          b: 'KT119348 VA',
          c: 'DOE, JANE',
        },
      ],
    };
  },
};
</script>
